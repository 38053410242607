<template>
  <div>
    <div class="container sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4">
        <!-- BEGIN: Login Info -->
        <div class="hidden xl:flex flex-col min-h-screen">
        </div>
        <!-- END: Login Info -->
        <!-- BEGIN: Login Form -->
        <div class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
          <div class="my-auto mx-auto xl:ml-20 bg-gray-900 dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto">
            <h2 class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left text-white">{{ $t('auth.forgot.forgot_forgot') }}</h2>
            <div class="intro-x mt-8">
              <input v-model="emailAddress" type="email" class="intro-x login__input form-control py-3 px-4 border-gray-700 block bg-gray-700 text-white" placeholder="type email address"/>
            </div>
            <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
              <button class="btn btn-primary py-3 px-4 w-full block align-top" @click="sendPasswordResetEmail">{{ $t('auth.forgot.forgot_send_reset') }}</button>
            </div>
          </div>
        </div>
        <!-- END: Login Form -->
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import userService from '@/services/userService.js';
import toast from '@/utils/toast';
import i18n from '@/plugins/i18n';


export default {
  setup() {
    const emailAddress = ref('');
    const sendPasswordResetEmail = async () => {
      try {
        await userService.forgotPassword(emailAddress.value)
        toast({
          status: 'success',
          title: i18n.global.t('auth.forgot.forgot_reset_success'),
          content: i18n.global.t('auth.forgot.forgot_please')
        })
      } catch (err) {
        console.error("reset passwrd request error: ", err)
        toast({
          status: 'error',
          title: i18n.global.t('auth.forgot.forgot_reset_error'),
          content: err
        })
      }
    }

    onMounted(() => {
      cash("body")
        .removeClass("main")
        .removeClass("error-page")
        .removeClass("register")
        .addClass("login");
    });

    return {
      emailAddress,
      sendPasswordResetEmail
    }
  },
};
</script>
