<template>
  <div>
    <div class="flex items-center mt-14">
      <!-- <h2 class="intro-y text-lg font-medium mr-auto">{{ $t('account.account_settings') }}</h2> -->
      <div v-if='showWelcomeBanner' class="welcome-box pl-24 py-10 w-full mb-12">
        <img class="mr-24" src="@/assets/images/checklistbro.svg" alt="">
        <h1>Welcome to DRIVEFIN Financial Portal!</h1>
        <p class="mt-2">Please setup your account and submit for approval to continue using our service.</p>
      </div>
    </div>
    <!-- BEGIN: Wizard Layout -->
    <div class="post__tabs nav nav-tabs nav-pc mt-4 shadow-md flex-col sm:flex-row bg-gray-300 text-gray-600" role="tablist">
      <a
        id="user-tab"
        data-toggle="tab"
        data-target="#user"
        href="javascript:;"
        class="w-full sm:w-40 py-4 text-center flex justify-center items-center"
        :class="routeName === 'GENIE_PROFILE_INFORMATION' ? 'active' : null"
        aria-selected="true"
        v-if='showProfileTab'
        @click="
          (e) => {
            e.preventDefault();
            gotoProfileInformation();
          }
        "
      >
        <UserIcon class="w-4 h-4 mr-2" /> {{ $t('account.account_profile') }}
      </a>
      <a
        id="user-tab"
        data-toggle="tab"
        data-target="#user"
        href="javascript:;"
        class="w-full sm:w-40 py-4 text-center flex justify-center items-center"
        :class="routeName === 'GENIE_COMPANY_PROFILE' ? 'active' : null"
        aria-selected="true"
        @click="
          (e) => {
            e.preventDefault();
            gotoCompanyInformation();
          }
        "
      >
        <HomeIcon class="w-4 h-4 mr-2" /> {{ $t('account.account_company') }}
      </a>
      <a
        id="user-tab"
        data-toggle="tab"
        data-target="#user"
        href="javascript:;"
        data-refs="bank"
        class="w-full sm:w-40 py-4 text-center flex justify-center items-center"
        :class="routeName === 'GENIE_COMPANY_BANK_INFORMATION' ? 'active' : null"
        aria-selected="true"
        @click="
          (e) => {
            e.preventDefault();
            gotoBankInformation();
          }
        "
      >
        <ApertureIcon class="w-4 h-4 mr-2" /> {{ $t('account.account_bank') }}
      </a>
      <a
        id="user-tab"
        data-toggle="tab"
        data-target="#user"
        href="javascript:;"
        data-refs="currency"
        class="w-full sm:w-40 py-4 text-center flex justify-center items-center"
        :class="routeName === 'GENIE_COMPANY_CURRENCY_INFORMATION' ? 'active' : null"
        aria-selected="true"
        @click="
          (e) => {
            e.preventDefault();
            gotoCurrencySettings();
          }
        "
      >
        <DollarSignIcon class="w-4 h-4 mr-2" /> {{ $t('account.account_currency') }}
      </a>
      <a
        id="user-tab"
        data-toggle="tab"
        data-target="#user"
        href="javascript:;"
        class="w-full sm:w-40 py-4 text-center flex justify-center items-center"
        :class="routeName === 'GENIE_COMPANY_KYC' ? 'active' : null"
        aria-selected="true"
        @click="
          (e) => {
            e.preventDefault();
            gotoKyc();
          }
        "
      >
        <PaperclipIcon class="w-4 h-4 mr-2" /> {{ $t('account.account_kyc') }}
      </a>
      <a
        id="user-tab"
        data-toggle="tab"
        data-target="#user"
        href="javascript:;"
        class="w-full sm:w-40 py-4 text-center flex justify-center items-center"
        :class="routeName === 'GENIE_COMPANY_HOLIDAYS' ? 'active' : null"
        aria-selected="true"
        @click="
          (e) => {
            e.preventDefault();
            gotoHolidays();
          }
        "
      >
        <CalendarIcon class="w-4 h-4 mr-2" /> {{ $t('account.account_holidays') }}
      </a>
    </div>
    <div class="flex box nav-sp">
      <div class="post__tabs nav nav-tabs flex-col my-10 pl-20 pr-10" role="tablist">
        <a
          id="user-tab"
          data-toggle="tab"
          data-target="#user"
          href="javascript:;"
          class="py-2 px-3 w-60 rounded"
          :class="routeName === 'GENIE_PROFILE_INFORMATION' ? 'active' : null"
          aria-selected="true"
          v-if='showProfileTab'
          @click="
            (e) => {
              e.preventDefault();
              gotoProfileInformation();
            }
          "
        >
          <UserIcon class="w-4 h-4 mr-2" /> {{ $t('account.account_profile') }}
        </a>
        <a
          id="user-tab"
          data-toggle="tab"
          data-target="#user"
          href="javascript:;"
          class="py-2 px-3 w-60 mt-2 rounded"
          :class="routeName === 'GENIE_COMPANY_PROFILE' ? 'active' : null"
          aria-selected="true"
          @click="
            (e) => {
              e.preventDefault();
              gotoCompanyInformation();
            }
          "
        >
          <HomeIcon class="w-4 h-4 mr-2" /> {{ $t('account.account_company') }}
        </a>
        <a
          id="user-tab"
          data-toggle="tab"
          data-target="#user"
          href="javascript:;"
          data-refs="bank"
          class="py-2 px-3 w-60 mt-2 rounded"
          :class="routeName === 'GENIE_COMPANY_BANK_INFORMATION' ? 'active' : null"
          aria-selected="true"
          @click="
            (e) => {
              e.preventDefault();
              gotoBankInformation();
            }
          "
        >
          <ApertureIcon class="w-4 h-4 mr-2" /> {{ $t('account.account_bank') }}
        </a>
        <a
          id="user-tab"
          data-toggle="tab"
          data-target="#user"
          href="javascript:;"
          data-refs="currency"
          class="py-2 px-3 w-60 mt-2 rounded"
          :class="routeName === 'GENIE_COMPANY_CURRENCY_INFORMATION' ? 'active' : null"
          aria-selected="true"
          @click="
            (e) => {
              e.preventDefault();
              gotoCurrencySettings();
            }
          "
        >
          <DollarSignIcon class="w-4 h-4 mr-2" /> {{ $t('account.account_currency') }}
        </a>
        <a
          id="user-tab"
          data-toggle="tab"
          data-target="#user"
          href="javascript:;"
          class="py-2 px-3 w-60 mt-2 rounded"
          :class="routeName === 'GENIE_COMPANY_KYC' ? 'active' : null"
          aria-selected="true"
          @click="
            (e) => {
              e.preventDefault();
              gotoKyc();
            }
          "
        >
          <PaperclipIcon class="w-4 h-4 mr-2" /> {{ $t('account.account_kyc') }}
        </a>
        <a
          id="user-tab"
          data-toggle="tab"
          data-target="#user"
          href="javascript:;"
          class="py-2 px-3 w-60 mt-2 rounded"
          :class="routeName === 'GENIE_COMPANY_HOLIDAYS' ? 'active' : null"
          aria-selected="true"
          @click="
            (e) => {
              e.preventDefault();
              gotoHolidays();
            }
          "
        >
          <CalendarIcon class="w-4 h-4 mr-2" /> {{ $t('account.account_holidays') }}
        </a>
      </div>
      <div class="intro-y py-10 lg:flex lg:divide-x divide-gray-500 w-full">
        <div class="w-full px-10">
          <router-view></router-view>
        </div>
      </div>
    </div>
    <!-- END: Wizard Layout -->
  </div>
</template>

<style>
  .nav.nav-tabs.nav-pc {
    display: none;
  }
  .nav-sp .nav.nav-tabs {
    display: flex;
  }
  .nav-sp .nav.nav-tabs a.active {
    background-color: rgba(250, 242, 248, var(--tw-bg-opacity));
    border: none;
    color: #76336A;
  }
  .nav-sp .nav.nav-tabs {
    border-right: 1px solid #D4D4DB;
  }
  @media only screen and (max-width: 992px) {
    .nav-sp .nav.nav-tabs {
      display: none;
    }
    .nav.nav-tabs.nav-pc {
      display: flex;
    }
  }
  .welcome-box > h1 {
    font-weight: 400;
    font-size: 28px;
    line-height: 40px;
    color: #9A428A;
  }
  .welcome-box {
    position: relative;
    background-color: #F4F4F6;
  }
  .welcome-box > img {
    position: absolute;
    right: 0px;
    top: -20.79%;
  }
</style>

<script>
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { appAxios } from "@/plugins/axios";
import companyService from "@/services/companyService";

export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const routeName = router.currentRoute._value.name
    let showWelcomeBanner = false;
    let showProfileTab = true;

    if (store.state.account.company_uuid == '00000000-0000-0000-0000-000000000000') {
      showWelcomeBanner = true;
      showProfileTab = false;
    }

    const gotoProfileInformation = () => router.push({ path: "/account/profile-information" });
    const gotoCompanyInformation = () => router.push({ path: "/account/" });
    const gotoBankInformation = () => router.push({ path: "/account/bank-information" });
    const gotoCurrencySettings = () => router.push({ path: "/account/currency-settings" });
    const gotoKyc = () => router.push({ path: "/account/kyc" });
    const gotoHolidays = () => router.push({ path: "/account/holidays" });

    onMounted(async () => {
      onMounted(() => {
        cash("body")
          .removeClass("main")
          .removeClass("error-page")
          .removeClass("login")
          .removeClass("register");
      });
      const companyIdApi = `/company/v1/user/${store.state.auth.user_id}`;
      await appAxios.get(companyIdApi).then((res) => {
        console.log(res.data)
        store.commit("account/SET_COMPANYID", { company_uuid: res.data });
      });
    });

    return {
      routeName,
      showWelcomeBanner,
      showProfileTab,
      gotoProfileInformation,
      gotoCompanyInformation,
      gotoBankInformation,
      gotoCurrencySettings,
      gotoKyc,
      gotoHolidays,
    };
  },
};
</script>
