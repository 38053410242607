<template>
  <div>
    <div class="container sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4">
        <!-- BEGIN: Login Info -->
        <div class="hidden xl:flex flex-col min-h-screen"></div>
        <!-- END: Login Info -->
        <!-- BEGIN: Login Form -->
        <div class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
          <div class="my-auto mx-auto xl:ml-20 bg-gray-900 text-white dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto">
            <h2 class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left">{{ $t('auth.login.login_portal') }}</h2>
            <div class="intro-x mt-2 text-center">
              {{ $t('auth.login.login_welcome') }}
            </div>
            <div class="intro-x mt-8">
              <div>
                <label>{{ $t('auth.login.login_username') }}</label>
                <input v-model="userName" type="text" class="intro-x login__input form-control py-3 px-4 border-gray-700 block bg-gray-700 text-white" :placeholder="$t('auth.login.login_ph_user')" v-on:keyup.enter="login({userName: userName, applicationDomain: 'genie', secret: password})"/>
              </div>
              <div class="mt-6">
                <div class="flex justify-between">
                  <label>{{ $t('auth.login.login_password') }}</label>
                  <a @click="gotoForgotPassword" class="text-theme-1 cursor-pointer">{{ $t('auth.login.login_forgot') }}</a>
                </div>
                <input v-model="password" type="password" class="intro-x login__input form-control py-3 px-4 border-gray-700 block bg-gray-700 text-white" :placeholder="$t('auth.login.login_ph_pass')" v-on:keyup.enter="login({userName: userName, applicationDomain: 'genie', secret: password})"/>
              </div>
            </div>
            <div class="intro-x flex text-gray-700 dark:text-gray-600 text-xs sm:text-sm mt-4">
              <div class="flex items-center mr-auto text-white">
                <input id="remember-me" type="checkbox" class="form-check-input border mr-2"/>
                <label class="cursor-pointer select-none" for="remember-me">{{ $t('auth.login.login_remember') }}</label>
              </div>
            </div>
            <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
              <button class="btn btn-primary w-full" :disabled='loading' @click="login({userName: userName, applicationDomain: 'genie', secret: password})">
                {{ $t('auth.login.login_login') }}
                <LoadingIcon v-if="loading" icon="oval" color="white" class="w-4 h-4 ml-2" />
              </button>
            </div>
            <div class="flex justify-center mt-4 intro-x">
              <label>{{ $t('auth.login.login_new') }}&nbsp;</label><a @click="gotoSignUp" class="text-theme-1 cursor-pointer">{{ $t('auth.login.login_new1') }}</a>
            </div>
            <!-- START: Localization language select -->
            <div class='flex justify-end mt-4 intro-x'>
              <div class='dropdown'>
                <button class='dropdown-toggle btn btn-primary' aria-expanded='false'>
                  {{computedLocale.name}}
                  <ChevronDownIcon class="w-4 h-4 ml-2" />
                </button>
                <div class='dropdown-menu'>
                  <div class='dropdown-menu__content box dark:bg-dark-1 p-2'>
                    <a
                      v-for='locale in locales'
                      href='javascript:;'
                      data-dismiss="dropdown"
                      class='block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md'
                      :key='locale.key'
                      @click="setLocale(locale)"
                    >{{locale.name}}</a>
                  </div>
                </div>
              </div>
            </div>
            <!-- END: Localization language select -->
          </div>
        </div>
        <!-- END: Login Form -->
      </div>
    </div>
    <div id="failed-notification-content" class="toastify-content hidden flex">
      <XCircleIcon class="text-theme-6" />
      <div class="ml-4 mr-4">
        <div class="font-medium">{{ $t('auth.login.login_login_fail') }}</div>
        <div class="text-gray-600 mt-1">{{ $t('auth.login.login_wrong') }}</div>
      </div>
		</div>
  </div>
</template>

<script>
import { onMounted, ref, computed } from "vue";
import { useStore } from "vuex"
import toast from '@/utils/toast'
import i18n from '@/plugins/i18n';
import { useRouter } from 'vue-router';

// import { useRecaptcha } from "vue-recaptcha-v3";

export default {
  setup() {

    // const { executeRecaptcha, recaptchaLoaded } = useRecaptcha();
    const store = useStore()
    const router = useRouter()
    const loading = ref(false)
    const locales = ref(store.state.main.locales)

    const recaptcha = async () => {
      // (optional) Wait until recaptcha has been loaded.
      // await recaptchaLoaded()

      // // Execute reCAPTCHA with action "login".
      // const token = await executeRecaptcha('login')
      // console.log(token)
      // Do stuff with the received token.
    }

    const login = async (userName, applicationDomain, secret) => {
      loading.value = !loading.value
      try {
        await store.dispatch("auth/login", userName, applicationDomain, secret)
      } catch (err) {
        loading.value = !loading.value
        toast({
          status: 'error',
          title: i18n.global.t('auth.login.login_login_fail'),
          content: i18n.global.t('auth.login.login_wrong')
        })
      }
    }

    const computedLocale = computed({
      get: () => store.state.main.locale
    })
    const setLocale = async (locale) => {
      await store.dispatch('main/setLocale', locale)
      router.go()
    }

    onMounted(() => {
      cash("body")
        .removeClass("main")
        .removeClass("error-page")
        .removeClass("register")
        .addClass("login");
    });
    return {
      recaptcha,
      loading,
      login,
      computedLocale,
      locales,
      setLocale
    }
  },
  data() {
    return {
      userName: '',
      password: '',
      recaptchSiteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY    }
  },
  methods: {
    gotoSignUp() {
      this.$router.push({path: 'register'})
    },
    gotoForgotPassword() {
      this.$router.push({path: 'forgot_password'})
    }
  }
};
</script>
