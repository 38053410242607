{
  "main": {
    "menu": {
      "menu_permissions": "Permissions",
      "menu_system_config": "System Config",
      "menu_user_management": "User Management",
      "menu_user_group": "User Group",
      "menu_new_users": "New Users",
      "menu_relasionship": "Relationship",
      "menu_workflow": "Workflow",
      "menu_dashboard": "Dashboard",
      "menu_invoices": "Invoices",
      "menu_companies": "Companies",
      "menu_company_onboarding": "Company Onboarding",
      "menu_profile": "Profile"
    },
    "ecosystem": {
      "ecosystem_public_ecosystem": "Public ecosystem"
    }
  },
  "account": {
    "account_settings": "Account Settings",
    "account_profile": "Profile",
    "account_role_funder_admin": "Funder Admin",
    "account_role_buyer_admin": "Buyer Admin",
    "account_role_seller_admin": "Seller Admin",
    "account_role_system_admin": "System Admin",
    "account_company": "Company",
    "account_bank": "Bank",
    "account_currency": "Currency",
    "account_kyc": "KYC",
    "account_holidays": "Holidays",
    "account_main_logout": "Logout",
    "account_main_appli": "Application",
    "account_main_hi": "Hi,",
    "bank": {
      "bank_info": "Bank Information",
      "bank_name": "Bank Name",
      "bank_remove": "Remove",
      "bank_branch_name": "Bank Branch Name",
      "bank_address": "Bank Address",
      "bank_account_number": "Bank Account Number",
      "bank_swift_code": "Bank Swift Code",
      "bank_currency": "Bank Currency",
      "bank_add_more": "Add another Bank",
      "bank_save": "Save",
      "bank_add_success": "Successfly Added Bank Info",
      "bank_add_fail": "Add Bank Info was failed!",
      "bank_please": "Please fillin correct value of the company profile.",
      "bank_update_success": "successfully updated banks",
      "bank_update_fail": "Please provide the missing fields.",
      "bank_ph_name": "Bank branch name",
      "bank_ph_address": "Bank address",
      "bank_ph_account": "Bank account number",
      "bank_ph_swift": "Bank swift code"
    },
    "company": {
      "company_info": "Company Information",
      "company_display_name": "Company Display Name",
      "company_legal_name": "Company Legal Name",
      "company_reg_no": "Registration No",
      "company_tax_no": "Tax Number",
      "company_reg_address": "Registered Address",
      "company_description": "Description About Company",
      "company_city": "City",
      "company_state": "State",
      "company_country": "Country",
      "company_phone": "Phone",
      "company_email": "Primary Email",
      "company_type": "Company Type",
      "company_company": "Company",
      "company_funder": "Funder",
      "company_save": "Save",
      "company_add_success": "Successfly Added Company Profile!",
      "company_add_fail": "Add Company profile was failed!",
      "company_please": "Please provide the missing fields.",
      "company_please1": "The ",
      "company_please2": " field is missing.",
      "company_ph_name": "Input company name",
      "company_ph_reg": "Registration no",
      "company_ph_tax": "Tax Number",
      "company_ph_add1": "Company Address Line 1",
      "company_ph_add2": "Company Address Line 2",
      "company_ph_add3": "Company Address Line 3",
      "company_ph_desc": "Describe about the company",
      "company_ph_city": "City",
      "company_ph_state": "State",
      "company_ph_contact": "Phone number",
      "company_ph_email": "Primary Email"
    },
    "currency": {
      "currency_info": "Currency Information",
      "currency_code": "CURRENCY CODE",
      "currency_name": "CURRENCY NAME",
      "currency_symbol": "CURRENCY SYMBOL",
      "currency_support": "SUPPORT",
      "currency_default": "DEFAULT",
      "currency_save": "Save",
      "currency_add_success": "Successfly Set Currencies!",
      "currency_add_fail": "Setting Currencies was failed!",
      "currency_please": "Please fillin correct value of the company profile."
    },
    "holidays": {
      "holidays_holidays": "Holidays",
      "holidays_add_new": "Add New LockDays",
      "holidays_no_events": "No events yet",
      "holidays_remove": "Remove after drop",
      "holidays_recycle_bin": "Recycle Bin",
      "holidays_no_recycle": "No recycle calender yet",
      "holidays_new": "New Lock Days",
      "holidays_title": "title",
      "holidays_number": "Number of Days",
      "holidays_time": "Time",
      "holidays_add": "Add",
      "holidays_ph_please": "Please add your lock days title"
    },
    "kyc": {
      "kyc_please_upload": "Please upload the required document(s).",
      "kyc_kyc_docs": "KYC Documents",
      "kyc_category": "DOCUMENT CATEGORY",
      "kyc_doc_name": "DOCUMENT NAME",
      "kyc_status": "STATUS",
      "kyc_act_log": "ACTIVITY LOG",
      "kyc_act_log1": "Activity Log",
      "kyc_updated_by": "Updated By",
      "kyc_time_stamp": "Time Stamp",
      "kyc_details": "Details",
      "kyc_actions": "ACTIONS",
      "kyc_save": "Save",
      "kyc_submit": "Submit for Approval",
      "kyc_file": "File Upload",
      "kyc_drag_drop": "Drag and drop here",
      "kyc_drag_drop1": "or",
      "kyc_browse": "browse",
      "kyc_doc_waiting": "Pending Upload",
      "kyc_doc_fail": "Kyc document upload failed"
    },
    "profile": {
      "profile_information": "Profile Information",
      "profile_username": "Username",
      "profile_display_name": "Display name",
      "profile_first_name": "First Name",
      "profile_last_name": "Last Name",
      "profile_email": "Email",
      "profile_phone_number": "Phone Number",
      "profile_save": "Save",
      "profile_email_fail": "Your email is not confirmed. Please check your inbox.",
      "profile_resend": "Click here to resend verification email",
      "profile_update_success": "Successfly updated your profile!",
      "profile_update_fail": "Update profile was failed!",
      "profile_please": "Please fillin correct value of the user profile.",
      "profile_ph_name": "Input company name",
      "profile_ph_reg": "Registration no",
      "profile_ph_tax": "Tax Number",
      "profile_ph_city": "City"
    }
  },
  "alerts": {
    "alerts_alert": "Alert Center",
    "alerts_show_all": "Show All Alerts",
    "alerts_all": "All",
    "alerts_unread": "Unread",
    "alerts_mark_read": "Mark all as Read",
    "alerts_ok": "OK",
    "alerts_cancel": "Cancel",
    "alerts_ph_search": "Search...",
    "alerts_ph_nomatch": "No matching records found",
    "alerts_ttl_notif": "Notifications",
    "alerts_ttl_timestamp": "Event Timestamp",
    "alerts_ttl_status": "LAST STATUS / PHASE",
    "alerts_ttl_actions": "Actions"
  },
  "auth": {
    "forgot": {
      "forgot_forgot": "Forgot Password",
      "forgot_send_reset": "Send password reset email",
      "forgot_reset_success": "Sent reset password successfuly",
      "forgot_please": "Please check your e-mail for reset password link",
      "forgot_reset_error": "Reset password request error",
      "forgot_ph_email": "Type email address"
    },
    "login": {
      "login_login": "Login",
      "login_register": "Register",
      "login_portal": "DRIVEFIN Financial Portal",
      "login_welcome": "Welcome back! Please enter your username and password to login into your account.",
      "login_username": "Username",
      "login_password": "Password",
      "login_forgot": "Forgot Password?",
      "login_remember": "Remember me",
      "login_login_fail": "Sign In failed!",
      "login_wrong": "Wrong user name and password.",
      "login_new": "New on our platform?",
      "login_new1": "Register an account",
      "login_ph_user": "Enter your username",
      "login_ph_pass": "Enter your password"
    },
    "register": {
      "register_portal": "DRIVEFIN Financial Portal",
      "register_new": "New account registration",
      "register_first_name": "First Name",
      "register_last_name": "Last Name",
      "register_username": "Username",
      "register_email": "Work Email",
      "register_display_name": "Display Name",
      "register_confirm_password": "Confirm Password",
      "register_sign_up": "Sign Up",
      "register_already": "Already have an account?",
      "register_instead": "Sign in instead",
      "register_ph_first": "First Name",
      "register_ph_last": "Last Name",
      "register_ph_user": "Username",
      "register_ph_email": "Work Email",
      "register_ph_display": "Display Name",
      "register_ph_passconfirm": "Password Confirmation",
      "register_s_confirm": "Confirm Password must be matched",
      "register_s_wrong": "Something was wrong in Register. Please try again later",
      "register_s_error": "Register error"
    },
    "reset": {
      "reset_reset": "Reset Password",
      "reset_few": "A few more clicks to sign in to your account. Manage all your",
      "reset_ecommerce": "e-commerce accounts in one place",
      "reset_instead": "Sign in instead",
      "reset_reset_success": "Reset password successfuly",
      "reset_you_can": "You can login with your new password",
      "reset_reset_fail": "Reset password failed",
      "reset_ph_newpass": "New Password"
    },
    "update": {
      "update_update": "Update Password",
      "update_current": "Confirm Password",
      "update_new": "New Password",
      "update_required": "Required",
      "update_update_success": "Update success!",
      "update_password_success": "The password successfully updated",
      "update_update_failed": "Update failed!",
      "update_please": "Please check the filled form."
    },
    "validate": {
      "validate_genie": "Genie",
      "validate_portal": "DRIVEFIN Financial Portal",
      "validate_new": "New account registration.",
      "validate_validate": "Validate Phone Number",
      "validate_enter": "Enter Verification code sent to your mobile",
      "validate_please_wait": "Please wait for (15) seconds",
      "validate_request": "to request a new code",
      "validate_validate_mobile": "Validate mobile number",
      "validate_back": "Back to sign up"
    }
  },
  "company": {
    "companies": {
      "companies_ph_nomatch": "No matching records found",
      "companies_ttl_display": "Display Name.",
      "companies_ttl_legal": "Legal Name",
      "companies_ttl_reg": "Reg No",
      "companies_ttl_tax": "Tax No",
      "companies_ttl_address": "Address",
      "companies_ttl_city": "City",
      "companies_ttl_country": "Country",
      "companies_ttl_contact": "Phone",
      "companies_ttl_email": "Email",
      "companies_ttl_actions": "ACTIONS"
    },
    "detail": {
      "detail_company_info": "Company Information",
      "detail_bank_info": "Bank Information",
      "detail_currency_settings": "Currency Settings",
      "detail_kyc_documents": "KYC Documents",
      "detail_user_lists": "User Lists"
    }
  },
  "dashboard": {
    "dashboard_dashboard": "Dashboard",
    "dashboard_reload_data": "Reload Data",
    "dashboard_pending": "Pending For Action",
    "dashboard_transaction_complete": "Transaction complete",
    "dashboard_awaiting_final_disbursement_acknowledgement_communication": "Email notification to funder on seller acknowledgement",
    "dashboard_awaiting_seller_acknowledge_receive_of_final_disbursement": "Seller Acknowledge Settlement from Funder",
    "dashboard_awaiting_final_fund_disbursement_notification_to_seller": "Email notification to seller for fund Settlement",
    "dashboard_awaiting_funder_final_disbursement": "Disbursement of balance settlement amount to seller",
    "dashboard_funder_acknowledged_receive_of_repayment": "Funder Acknowledge Repayment from Buyer",
    "dashboard_awaiting_funder_acknowledge_repayment": "Email notification to Funder on Repayment from Buyer",
    "dashboard_awaiting_buyer_repayment_on_due_date": "Buyer Makes Payment On Due Date",
    "dashboard_awaiting_disbursement_acknowledgement_communication": "Awaiting disbursement acknowledgement communication",
    "dashboard_awaiting_seller_acknowledge_disbursement": "Awaiting seller acknowledge disbursement",
    "dashboard_awaiting_fund_disbursement_notification_to_seller": "Awaiting fund disbursement notification to seller",
    "dashboard_awaiting_funder_disbursement": "Awaiting funder disbursement",
    "dashboard_awaiting_first_disbursement_acknowledgement_communication": "Email notification to funder on seller acknowledgement",
    "dashboard_awaiting_seller_acknowledge_receive_of_first_disbursement": "Seller Acknowledge Disbursement from Funder",
    "dashboard_awaiting_first_fund_disbursement_notification_to_seller": "Email notification to seller for fund disbursement",
    "dashboard_awaiting_funder_first_disbursement": "Disbursement of the amount financed less interest and fees",
    "dashboard_awaiting_bidding_result": "Email notification to Supplier and Buyer",
    "dashboard_bidding_in_progress": "Funder Approve Transaction",
    "dashboard_invite_funders_to_bid": "Email notification to Funder",
    "dashboard_awaiting_seller_acknowledgement": "Awaiting seller acknoledgement",
    "dashboard_awaiting_buyer_acknowledgement": "Buyer Approve Transaction",
    "dashboard_start": "Financing Request Submitted",
    "dashboard_notification_sent_to_buyer": "Notification send to buyer",
    "dashboard_transaction_acknowledged_by_buyer": "Transaction acknowledged by buyer",
    "dashboard_invitation_sent_to_funders": "Invitation send to funders",
    "dashboard_transaction_approved_by_funder": "Transaction approved by funder",
    "dashboard_first_fund_disbursement_instruction_sent_to_funder": "First fund disbursement instruction send to funder",
    "dashboard_first_fund_disbursed_by_funder": "First fund disbursed by funder",
    "dashboard_first_fund_disbursement_notification_sent_to_seller": "First fund disbursement notification send to seller",
    "dashboard_receipt_of_first_fund_acknowledged_by_seller": "Receipt of first fund acknowledge by seller",
    "dashboard_repayment_instruction_sent_to_buyer": "Repayment instruction send to buyer",
    "dashboard_repaid_by_buyer": "Repaid by buyer",
    "dashboard_receipt_of_repayment_acknowledged_by_funder": "Receipt of repayment acknowledge by funder",
    "dashboard_final_fund_disbursement_instruction_sent_to_funder": "Final fund disbursement instruction send to funder",
    "dashboard_final_fund_disbursed_by_funder": "Final fund disbursed by funder",
    "dashboard_final_fund_disbursement_notification_sent_to_seller": "Final fund disbursement notification send to seller",
    "dashboard_receipt_of_final_fund_acknowledged_by_seller": "Receipt of final fund acknowledge by seller",
    "dashboard_transaction_declined_by_buyer": "Transaction declined by buyer",
    "dashboard_transaction_not_approved_by_funder": "Transaction not approved by funder",
    "dashboard_receipt_of_first_funds_declined_by_seller": "Receipt of first funds declined by seller",
    "dashboard_receipt_of_repayment_declined_by_funder": "Receipt of repayment declined by funder",
    "dashboard_receipt_of_final_funds_declined_by_seller": "Receipt of final funds declined by seller",
    "dashboard_notification_sent_to_seller": "Notification send to seller",
    "dashboard_transaction_acknowledged_by_seller": "Transaction acknowledged by seller",
    "dashboard_fund_disbursement_instruction_sent_to_funder": "Fund disbursement instruction send to funder",
    "dashboard_fund_disbursed_by_funder": "Fund disbursed by funder",
    "dashboard_fund_disbursement_notification_sent_to_seller": "Fund disbursement notification send to seller",
    "dashboard_receipt_of_fund_acknowledged_by_seller": "Receipt of fund acknowledge by seller",
    "dashboard_notification_sent_to_funder": "Notification send to funder",
    "dashboard_invoice": "Invoice Batch",
    "dashboard_created": "Created On :",
    "dashboard_view": "View",
    "dashboard_no_pending": "No pending item",
    "dashboard_calendar": "Upcoming Holiday Calendar",
    "borrowers": {
      "borrowers_companies": "Active Borrower Companies"
    },
    "cards": {
      "cards_line_info": "LINE INFORMATION",
      "cards_limit": "Available Limit: ",
      "cards_funded_amount": "Funded Amount: ",
      "cards_total_allocated": "Total Allocated Amount:",
      "cards_outstand_amount": "Outstanding Amount For Disbursement: ",
      "cards_utilized_amount": "Utilized Amount: ",
      "cards_overall_limit": "Overall Limit: ",
      "cards_financing_funded": "Financing Funded",
      "cards_count": "Count: ",
      "cards_total_amount": "Total Amount: ",
      "cards_requested": "Financing Requested"
    }
  },
  "eco": {
    "relationship": {
      "relationship_systems": "Eco Systems",
      "relationship_create": "Create Eco System",
      "relationship_ph_nomatch": "No matching records found",
      "relationship_ttl_name": "Name",
      "relationship_ttl_funder": "Funder",
      "relationship_ttl_buyer": "Buyer",
      "relationship_ttl_seller": "Seller",
      "relationship_ttl_expire": "Expired Date",
      "relationship_ttl_status": "Status",
      "relationship_ttl_actions": "ACTIONS"
    },
    "modal": {
      "modal_system": "Eco System ",
      "modal_name": "Name: ",
      "modal_buyer": "Buyer Company: ",
      "modal_seller": "Seller Company: ",
      "modal_funder": "Funder Company: ",
      "modal_seller_credit": "Seller Credit: ",
      "modal_buyer_credit": "Buyer Credit: ",
      "modal_base_currency": "Base Currency Code: ",
      "modal_interest": "Interest Rate(%): ",
      "modal_interest_duration": "Interest Rate Duration: ",
      "modal_expired": "Expired Date:",
      "modal_status": "Status:",
      "modal_buyer_workflow": "Buyer Led Workflow",
      "modal_seller_workflow": "Seller Led Workflow",
      "modal_delete": "Delete",
      "modal_active": "Active",
      "modal_save": "Save"
    },
    "workflow": {
      "workflow_workflow": "Workflow"
    }
  },
  "error": {
    "denied": {
      "denied_403": "403",
      "denied_oops": "Oops. You have no access to this Page.",
      "denied_mistyped": "You may have mistyped the address, so access this page please require access require to super admin. ",
      "denied_back": "Back to Home"
    },
    "error": {
      "error_404": "404",
      "error_oops": "Oops. This page has gone missing.",
      "error_mistyped": "You may have mistyped the address or the page may have moved.",
      "error_back": "Back to Home"
    }
  },
  "invoice": {
    "invoice_search": "Search",
    "invoice_page_size": "Page Size",
    "invoice_invoices": "Invoices",
    "invoice_upload_invoice": "Upload",
    "invoice_upload_invoice2": "Invoice",
    "invoice_field": "Field",
    "invoice_last_updated": "Last Updated By",
    "invoice_seller": "Seller",
    "invoice_buyer": "Buyer",
    "invoice_funder": "Funder",
    "invoice_batch_no": "Batch No.",
    "invoice_batch_st": "Batch Status",
    "invoice_batch_remark": "Batch Remark",
    "invoice_type": "Type",
    "invoice_like": "Equals",
    "invoice_nlike": "Not equals",
    "invoice_value": "Value",
    "invoice_go": "Go",
    "invoice_reset": "Reset",
    "invoice_pending_action": "Pending Action",
    "invoice_my_invoice": "My Invoice",
    "invoice_my_bid": "My Bid",
    "invoice_invoice_partner": "Invoice From My Partner",
    "invoice_empty_table": "No data available in table",
    "invoice_processing": "Processing...",
    "invoice_ph_search": "Search...",
    "invoice_ph_nomatch": "No data available in table",
    "invoice_ttl_batch": "BATCH NO.",
    "invoice_ttl_buyer": "BUYER",
    "invoice_ttl_seller": "SELLER",
    "invoice_ttl_total": "TOTAL AMOUNT",
    "invoice_ttl_lastupdate": "LAST UPDATED BY",
    "invoice_ttl_stage": "CURRENT STAGE",
    "invoice_ttl_bidendtime": "BID END TIME",
    "invoice_ttl_remark": "BATCH REMARK",
    "invoice_ttl_status": "BATCH STATUS",
    "invoice_ttl_created": "CREATED AT",
    "invoice_ttl_actions": "ACTIONS",
    "upload": {
      "upload_upload_invoice": "Upload Invoice ",
      "upload_upload_selecttype": "Select Document Type",
      "upload_company_name": "Company Name",
      "upload_seller_name": "Seller Name",
      "upload_buyer_name": "Buyer Name",
      "upload_bid_end": "Bid End Time",
      "upload_select_disb": "Select disbursable Bank Account",
      "upload_select_disb1": "Select Disbursement Bank Account",
      "upload_batch_remark": "Input batch Remark",
      "upload_upload_documents": "Upload Support Documents",
      "upload_payment_date": "Payment Due date must be future date",
      "upload_amount_zero": "Amount must not be zero",
      "upload_uploading": "Uploading",
      "upload_number": "Document Number ",
      "upload_type": "Document Type ",
      "upload_date": "Document Date ",
      "upload_payment_date1": "Payment Due Date ",
      "upload_currency_code": "Currency Code ",
      "upload_amount": "Amount ",
      "upload_support": "Support Document ",
      "upload_action": "Action ",
      "upload_remark": "Batch Remark",
      "upload_cancel": "Cancel ",
      "upload_submit": "Submit ",
      "upload_upload_fail": "Upload failed!",
      "upload_upload_s_file_error": "File format error",
      "upload_upload_s_invoice_fail": "Invoice uploading is failed"
    },
    "support": {
      "support_support": "uploading",
      "support_s_cannot_upload1": "Can not upload ",
      "support_s_cannot_upload2": " file",
      "support_s_upload_fail": "Support document upload is failed"
    },
    "detail": {
      "detail_upload_document": "Upload Document",
      "invoice": "Invoice",
      "batch_no": "Batch No: ",
      "uploads": "Invoice Overview",
      "yellow_warning": "Please upload supporting documents for your invoice(s).",
      "no": "INVOICE NO ",
      "amount": "INVOICE AMOUNT ",
      "date": "INVOICE DATE ",
      "payment_date": "PAYMENT DUE DATE ",
      "upload_date": "UPLOAD DATE ",
      "actions": "ACTIONS ",
      "more": "View More",
      "less": "View Less",
      "provenance_txt": "Provenance",
      "documents": {
        "documents_supporting": "SUPPORTING DOCUMENTS",
        "documents_uploaded": "DATE UPLOADED",
        "documents_uploaded_by": "UPLOADED BY",
        "documents_verifying": "Verifying"
      },
      "provenance": {
        "provenance_verifying": "Verifying",
        "provenance_verified": "Verified",
        "provenance_not_verified": "Not Verified",
        "provenance_pending": "Pending",
        "provenance_not_started": "Not Started",
        "provenance_updated": "Updated On : ",
        "provenance_hash": "Transaction Hash : ",
        "provenance_approval": "Approval by : "
      }
    },
    "batch": {
      "signature": {
        "signature_undo": "Undo signature",
        "signature_clear": "Clear signature"
      },
      "eco": {
        "eco_batch_details": "Batch Details: ",
        "eco_batch_workflow_seller_led": "Seller Led",
        "eco_batch_workflow_buyer_led": "Buyer Led",
        "eco_batch_status_expired": "Expired",
        "eco_batch_status_rejected": "Rejected",
        "eco_batch_status_funded": "Funded",
        "eco_batch_status_completed": "Completed",
        "eco_batch_status_pending": "Pending",
        "eco_batch_status_pending_repayment": "Pending Repayment",
        "eco_batch_status_pending_final_disbursement": "Pending Final Disbursement",
        "eco_batch_status_bidding_expired": "Bidding Expired",
        "eco_batch_info": "Batch Information",
        "eco_buyer_company": "Buyer Company",
        "eco_seller_company": "Seller Company",
        "eco_funder_company": "Funder Company",
        "eco_no_batch": "No of batch entries",
        "eco_upload_date": "Upload Date",
        "eco_total_amount": "Total Amount",
        "eco_bank_details": "Bank Details",
        "eco_disbursement_account": "Disbursement Bank Account",
        "eco_account_no": "Account number: ",
        "eco_bank_name": "Bank Name: ",
        "eco_address": "Address: ",
        "eco_swift": "Swift Code: ",
        "eco_formula": "Formula",
        "eco_interest": "Interest Rate (Annual Rate %)",
        "eco_interest_earn": "Interest Earn",
        "eco_platform_fee": "Platform Fee Amount",
        "eco_disbursement_amount": "Disbursement Amount Financed Less Interest and Fees",
        "eco_repayment_account": "Repayment Bank Account",
        "eco_repayment_amount": "Repayment Amount To Funder",
        "eco_repayment_date": "Repayment Date",
        "eco_decline": "Decline",
        "eco_approve": "Approve",
        "eco_submit_disb": "Submit Disbursement",
        "eco_receive_disb": "Acknowledge Receive of Disbursement",
        "eco_upload_advice": "Upload Repayment Advice",
        "eco_repay_advice": "Acknowledge Repayment Advice",
        "eco_approve_invoice": "Approve Invoice ",
        "eco_batch_no": "Batch Number",
        "eco_invoice_upload": "Invoice Uploaded Date",
        "eco_invoice_amount": "Invoice Amount",
        "eco_payment_due": "Payment Due Date",
        "eco_select_disb": "Select Disbursement Bank Account",
        "eco_remark": "Remark",
        "eco_cancel": "Cancel  ",
        "eco_approve_trx": "Approve Transaction ",
        "eco_total_invoice": "Total Invoice Amount",
        "eco_value_date": "Value Date",
        "eco_invoice_due": "Invoice Due Date",
        "eco_no_days": "Numbers of Days",
        "eco_decline_invoice": "Decline Invoice ",
        "eco_upload_disb": "Upload fund disbursement payment advice ",
        "eco_payment_upload": "Payment Advice File Upload",
        "eco_or": "or",
        "eco_browse": "browse",
        "eco_currency": "Currency Code",
        "eco_submit": "Submit",
        "eco_advice_no": "Payment Advice Number",
        "eco_advice_file": "Payment Advice File",
        "eco_advice_amount": "Payment Advice Amount",
        "eco_advice_date": "Payment Advice Date",
        "eco_confirm": "Confirm",
        "eco_receive_repay": "Acknowledge Receive of Repayment",
        "eco_drag_drop": "Drag and drop here",
        "eco_s_approve_fail": "Approving is failed",
        "eco_s_please_interest": "Please set the Interate Rate",
        "eco_s_please_valid": "Please select the valid repayment bank account",
        "eco_s_seller_ack_fail": "Seller acknowledging is failed",
        "eco_s_decline_fail": "Declining is failed",
        "eco_s_funder_approve_fail": "Funder acknowledge approving is Failed",
        "eco_s_funder_decline_fail": "Funder acknowledge declining is Failed",
        "eco_s_platform_error": "Platform fee calculation error",
        "eco_s_funder_advice_fail": "Funder submitting disbursement advice is Failed",
        "eco_s_funder_advice_req": "The Payment Advice Number/Amount field is required.",
        "eco_s_funder_payment_req": "Please upload Payment Advice File",
        "eco_s_ack_fail": "Acknowledge Failed",
        "eco_s_upload_fail": "Uploading repayment advice is Failed",
        "eco_s_funder_ack_fail": "Funder Acknowledge Failed",
        "eco_s_signature_req": "Signature/Remark field is required!",
        "eco_s_funder_decline": "Funder Decline Failed",
        "eco_batch_message1": "The buyer did not approve this invoice due to passed bid end time",
        "eco_batch_message2": "This transaction was not approved by the funder",
        "eco_batch_message3": "This invoice has been expired. The payment Due Date is"
      },
      "led": {
        "led_maturity": "Maturity Date",
        "led_note": "Note: ",
        "led_interest_r": "Interest Rate(%)",
        "led_monthly": "Monthly",
        "led_yearly": "Yearly",
        "led_disb_amt_fee": "Disbursement Amount Financed Less Platform Fee",
        "led_reject_fail": "Rejecting invoice is failed"
      }
    }
  },
  "onboard": {
    "onboard_pending_approval": "Pending Approval",
    "onboard_pending_request": "Pending Request",
    "onboard_no_data": "No available data",
    "onboard_rejected_request": "Rejected Request",
    "onboard_remove_company": "Remove this company?",
    "onboard_onboard": "Company Onboard",
    "onboard_company_display_name": "Company Display Name",
    "onboard_required": "Required",
    "onboard_company_email": "Company's Email",
    "onboard_invite": "Invite",
    "onboard_onboared": "Onboarded Companies",
    "onboard_credit": "CREDIT LIMIT ",
    "onboard_start": "START DATE ",
    "onboard_end": "END DATE ",
    "onboard_status": "STATUS ",
    "onboard_actions": "ACTIONS ",
    "onboard_reinvite": "Reinvite ",
    "onboard_available": "Available Companies",
    "onboard_company_name": "COMPANY NAME ",
    "onboard_company_code": "COMPANY CODE ",
    "onboard_email": "EMAIL ",
    "onboard_display_name": "DISPLAY NAME ",
    "onboard_address": "ADDRESS ",
    "onboard_pending": "Pending",
    "onboard_invite_company": "Invite Company ",
    "onboard_currency_code": "Currency Code",
    "onboard_credit_limit": "Credit Limit",
    "onboard_end_date": "End Date",
    "onboard_cancel": "Cancel ",
    "onboard_invite_success": "Invite success!",
    "onboard_reject_success": "Decline success!",
    "onboard_approve_success": "Approve success!",
    "onboard_remove_success": "Remove success!",
    "onboard_failed": "Failed!",
    "onboard_invite_fail": "Invite failed!",
    "onboard_invite_success1": "Successfully invited the company",
    "onboard_invite_success2": "Successfully rejected the invite",
    "onboard_invite_success3": "Successfully approved the invite",
    "onboard_invite_success4": "Successfully removed the company",
    "onboard_upload_fail": "Upload failed!",
    "onboard_ph_display": "Company display name",
    "onboard_s_please": "Please fill in the required fields"
  },
  "permissions": {
    "add": {
      "add_create": "Create Permission",
      "add_name": "Permission Name",
      "add_required": "Required",
      "add_resource": "Resource Url",
      "add_verbs": "Access Verbs",
      "add_get": "Get",
      "add_post": "Post",
      "add_put": "Put",
      "add_delete": "Delete",
      "add_valid": "Valid Until",
      "add_format": "Required Date Format",
      "add_unexpired": "Unexpired",
      "add_type": "Type",
      "add_menu": "Menu",
      "add_api": "API",
      "add_create_success": "Create success!",
      "add_create_success_done": "Creating permission is successfully done.",
      "add_success": "Successfully create new permission",
      "add_create_fail": "Create failed!",
      "add_valid_error": "Validation error.",
      "add_please_fields": "Please check the form fields.",
      "add_please.": "Please check the filled form."
    },
    "edit": {
      "edit_update": "Update Permission",
      "edit_name": "Permission Name",
      "edit_required": "Required",
      "edit_resource": "Resource Url",
      "edit_verbs": "Access Verbs",
      "edit_get": "Get",
      "edit_post": "Post",
      "edit_put": "Put",
      "edit_delete": "Delete",
      "edit_valid": "Valid Until",
      "edit_format": "Required Date Format",
      "edit_unexpired": "Unexpired",
      "edit_type": "Type",
      "edit_menu": "Menu",
      "edit_api": "API",
      "edit_update_success": "Update success!",
      "edit_success": "Successfully Update the permission",
      "edit_update_fail": "Update failed!",
      "add_valid_error": "Validation error.",
      "add_please_fields": "Please check the form fields.",
      "edit_please.": "Please check the filled form."
    },
    "list": {
      "edit_list_permissions": "Permissions",
      "edit_list_add": "Add New Permission",
      "edit_list_print": "Print",
      "edit_list_excel": "Export to Excel",
      "edit_list_pdf": "Export to PDF",
      "edit_list_name": "PERMISSION NAME",
      "edit_list_url": "URL",
      "edit_list_expire": "EXPIRE DATE",
      "edit_list_type": "TYPE",
      "edit_list_actions": "ACTIONS",
      "edit_list_edit": "Edit",
      "edit_list_delete": "Delete",
      "edit_list_confirm": "Are you sure?",
      "edit_list_confirm_delete": "Do you really want to delete these permission? <br />This process",
      "edit_list_cannot": "cannot be undone.",
      "edit_list_cancel": "Cancel",
      "edit_delete_done": "Deleting permission is successfully done",
      "edit_delete_fail": "Deleting permission is failed",
      "edit_list_ph_search": "Search..."
    }
  },
  "roles": {
    "roles_list_permissions": "Permissions",
    "roles_list_grant": "Grant Permissions",
    "roles_list_grant_role ": "Grant Permissions to Role ",
    "roles_list_available": "Available permission list",
    "roles_list_name": "PERMISSION NAME",
    "roles_list_url": "URL",
    "roles_list_expire": "EXPIRE DATE",
    "roles_list_type": "TYPE",
    "roles_list_cancel": "Cancel ",
    "roles_list_ok": "OK ",
    "roles_list_confirm": "Are you sure?",
    "roles_list_confirm_remove": "Do you really want to Remove",
    "roles_list_confirm_remove1": "Permission? ",
    "roles_list_confirm_remove2": "This process cannot be undone. ",
    "roles_list_remove": "Remove ",
    "roles_list_ph_nomatch": "No matching records found",
    "roles_list_ttl_name": "Permission Name",
    "roles_list_ttl_type": "Type",
    "roles_list_ttl_actions": "ACTIONS",
    "grant": {
      "grant_delete_success": "Deleting Role is successfully done",
      "grant_delete_success_has1": "The ",
      "grant_delete_success_has2": " has been deleted",
      "grant_delete_fail": "Deleting Role is failed",
      "grant_delete_permission_done": "Delete permission is successfully done",
      "grant_delete_permission_fail": "Delete permission is failed",
      "grant_add_permission_done": "Add permission is successfully done",
      "grant_add_permission_fail": "Add permission is failed",
      "grant_access": "Grant Access to Role"
    },
    "list": {
      "list_roles": "Roles",
      "list_add": "Add New Role",
      "list_name": "User Role Name",
      "list_confirm": "Are you sure?",
      "list_confirm_delete": "Do you really want to delete",
      "list_confirm_delete1": "Role? ",
      "list_confirm_delete2": "This process cannot be undone. ",
      "list_cancel": "Cancel ",
      "list_delete": "Delete",
      "list_ph_search": "Search...",
      "list_ph_input": "Input user role name",
      "list_ph_nomatch": "No matching records found",
      "list_ttl_name": "Role Name",
      "list_ttl_actions": "ACTIONS"
    }
  },
  "settings": {
    "configure": {
      "settings_configure_configure": "System Configure",
      "settings_configure_add_group": "Add New Group",
      "settings_configure_name": "name",
      "settings_configure_value": "value",
      "settings_configure_data_type": "data type",
      "settings_configure_save": "Save",
      "settings_configure_remove": "Remove",
      "settings_configure_add_configuration": "Add Configuration",
      "settings_configure_json": "Json",
      "settings_configure_cancel": "Cancel",
      "settings_configure_add": "Add",
      "settings_configure_confirm": "Are you sure?",
      "settings_confirmdddd": "This process cannot be undone. ",
      "settings_confirm": "This process cannot be undone. ",
      "settings_configure_cannot": "This process cannot be undone. ",
      "settings_configure_delete": "Delete ",
      "settings_add_done": "Add new group is successfully done",
      "settings_add_fail": "Add new group failed!",
      "settings_save_done": "Saving configuration is successfully done",
      "settings_add_config_done": "Adding configuration is successfully done",
      "settings_add_config_fail": "Adding configuration is failed",
      "settings_delete_config_done": "Deleting configuration is successfully done",
      "settings_delete_config_fail": "Deleting configuration is failed",
      "settings_configure_group_name": "Group Name"
    }
  },
  "users": {
    "add": {
      "users_add_create": "Create User",
      "users_add_first_name": "First Name",
      "users_add_required": "Required",
      "users_add_last_name": "Last Name",
      "users_add_email": "Email",
      "users_add_required_email": "Required, email address format",
      "users_add_user_name": "User Name",
      "users_add_display_name": "Display Name",
      "users_add_user_role": "User Role",
      "users_add_multiple": "multiple",
      "users_add_password": "Password",
      "users_add_confirm_password": "Confirm Password",
      "users_add_update_success": "Create success!",
      "users_add_please": "Please check your e-mail for further info!",
      "users_add_update_fail": "Create failed!",
      "users_add_please1": "Please check the filled form."
    },
    "edit": {
      "users_edit_user_data": "User Data",
      "users_edit_company_info": "Company Information",
      "users_edit_bank_info": "Bank Information",
      "users_edit_currency": "Currency Settings",
      "users_edit_kyc_docs": "KYC Documents",
      "users_edit_first_name": "First Name",
      "users_edit_required": "Required",
      "users_edit_last_name": "Last Name",
      "users_edit_email": "Email",
      "users_edit_required_email": "Required, email address format",
      "users_edit_display_name": "Display Name",
      "users_edit_user_role": "User Role",
      "users_edit_multiple": "multiple",
      "users_edit_update_user": "Update User",
      "users_edit_update_success": "Update success!",
      "users_edit_please": "Please check your e-mail for further info!",
      "users_edit_update_fail": "Update failed!",
      "users_edit_please1": "Please check the filled form."
    },
    "new": {
      "users_new_users": "Users Layout",
      "users_new_dialog": "open confirm dialog",
      "users_new_ph_nomatch": "No matching records found",
      "users_new_ttl_reg": "Registration No",
      "users_new_ttl_name": "Company Name",
      "users_new_ttl_legal": "Company Legal Name",
      "users_new_ttl_add": "Address",
      "users_new_ttl_type": "Type",
      "users_new_ttl_contact": "Phone",
      "users_new_ttl_email": "Email",
      "users_new_ttl_actions": "ACTIONS"
    },
    "list": {
      "users_list_users": "Users Layout",
      "users_list_add": "Add New User",
      "users_list_showing": "Showing 1 to 10 of 150 entries",
      "users_list_edit": "Edit",
      "users_list_delete": "Delete",
      "users_list_ellipsis": "...",
      "users_list_ph_search": "Search..."
    }
  }
}